.calendarWrapper {
  width: 100%;
  height: 100%;
  padding: 16px;
}

.calendarBottom {
  width: 100%;
  height: calc(100% - 52px);
}
