@use 'styles/constants/levels';
@use 'styles/constants/sizes';
@use 'styles/mixins/flexbox';

.layoutNavBar {
  width: 100%;
  margin-top: 20px;

  & > ul {
    @include flexbox.flexbox(center, start);
    width: 100%;
    height: sizes.$FONT_SUBTITLE;
    overflow-x: auto;
    overflow-y: hidden;
  }

  &.isPlayPage {
    position: relative;
  }

  .goBackButton {
    position: absolute;
    transition: color 0.2s ease-in;

    svg {
      height: 20px;
      fill: var(--color-border);
    }

    &:hover {
      color: var(--color-text2);
    }
  }
}

.layoutNavElement {
  margin-right: 16px;

  a {
    font-family: 'Nanum Myeongjo', serif;
    font-size: sizes.$FONT_SUBTITLE;
    font-weight: 700;
    color: var(--color-text1);
    opacity: 0.3;
    transition: opacity 0.2s ease-in;

    &:hover {
      opacity: 0.7;
    }

    &.isActive {
      opacity: 1;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}
