@use 'styles/constants/sizes';
@use 'styles/mixins/flexbox';

.errorWrapper {
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: 'Nanum Myeongjo', serif;
  color: var(--color-text1);
}

.errorComponentBox {
  @include flexbox.flexbox(center, center);
  width: 100%;
  height: 40%;
  border: 2px solid var(--color-border);

  svg {
    width: 100px;
    fill: var(--color-text2);
  }
}

.errorInformation {
  width: 100%;
  height: calc(60% - 16px);
  padding: 0 8px;
  margin-top: 16px;

  .playerDate {
    margin-bottom: 8px;
    font-size: sizes.$FONT_SMALL;
    font-style: italic;
    color: var(--color-text2);
  }

  .errorInfo {
    margin-bottom: 16px;
  }

  .errorMessage {
    max-height: calc(100% - 78px - 23.5px);
    padding: 12px 16px;
    margin-bottom: 16px;
    overflow: auto;
    line-height: 20px;
    white-space: pre-wrap;
    border: 1px solid var(--color-border);
    border-radius: 4px;
  }
}
