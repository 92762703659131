$WHITE: #ffffff;

$THEME_SUNRISE: #f7cd6b;
$THEME_DAYLIGHT: #d3e9f1;
$THEME_SUNSET: #f79e7e;
$THEME_NIGHT: #607ea6;

:root[color-primary='theme-sunrise'] {
  --container-background-color: #fff9f1;
  --color-primary1: #f7cd6b;
  --color-primary2: #ea8d57;
  --color-primary3: #140905;
  --color-secondary1: #fbd9a2;
  --color-secondary2: #fde5c2;
  --color-text1: #402b23;
  --color-text2: #9a7b46;

  --color-border: #b3a79f;
  --color-shadow: #fff9e0;
}

:root[color-primary='theme-daylight'] {
  --container-background-color: #ffffff;
  --color-primary1: #278fd0;
  --color-primary2: #6aaed9;
  --color-primary3: #d3e9f1;
  --color-secondary1: #b0ceed;
  --color-secondary2: #d0e2f4;
  --color-text1: #274159;
  --color-text2: #7b94a4;

  --color-border: #a9b3bd;
  --color-shadow: #b0cddf;
}

:root[color-primary='theme-sunset'] {
  --container-background-color: #242b45;
  --color-primary1: #455384;
  --color-primary2: #a65d7f;
  --color-primary3: #f79e7e;
  --color-secondary1: #7a5072;
  --color-secondary2: #574260;
  --color-text1: #c8c5d8;
  --color-text2: #8c8da7;

  --color-border: #565a72;
  --color-shadow: #38436b;
}

:root[color-primary='theme-night'] {
  --container-background-color: #252933;
  --color-primary1: #141a26;
  --color-primary2: #2e3d59;
  --color-primary3: #607ea6;
  --color-secondary1: #4a556c;
  --color-secondary2: #3c4354;
  --color-text1: #dae4f8;
  --color-text2: #8e95a6;

  --color-border: #5d626f;
  --color-shadow: #5b5a69;
}

$BOX_SHADOW: 0 0 16px 1px var(--color-shadow);
