@use 'styles/constants/colors';
@use 'styles/constants/levels';
@use 'styles/constants/sizes';
@use 'styles/mixins/flexbox';

.layoutBackground {
  @include flexbox.flexbox(center, top);
  width: 100vw;
  height: 100vh;
  padding: 20px 0;

  :root[color-primary='theme-sunrise'] & {
    background: linear-gradient(var(--color-primary1), var(--color-primary2) 25%, var(--color-primary3));
  }

  :root[color-primary='theme-daylight'] & {
    background: linear-gradient(var(--color-primary1), var(--color-primary2) 25%, var(--color-primary3));
  }

  :root[color-primary='theme-sunset'] & {
    background: linear-gradient(var(--color-primary1), var(--color-primary2) 75%, var(--color-primary3));
  }

  :root[color-primary='theme-night'] & {
    background: linear-gradient(var(--color-primary1), var(--color-primary2) 75%, var(--color-primary3));
  }
}

.layoutWrapper {
  @include flexbox.flexbox(center, center);
  flex-direction: column;
  width: 80%;
  max-width: 480px;
  height: 100%;

  & > button {
    height: sizes.$LAYOUT_IMAGE_SIZE;
  }

  button > img {
    width: sizes.$LAYOUT_IMAGE_SIZE;
    height: sizes.$LAYOUT_IMAGE_SIZE;
    object-fit: cover;
  }
}

.layoutContainer {
  width: 100%;
  height: calc(100% - sizes.$LAYOUT_IMAGE_SIZE);
  padding: sizes.$LAYOUT_PADDING;
  background-color: var(--container-background-color);
  border-radius: sizes.$LAYOUT_BORDER_RADIUS;
  box-shadow: colors.$BOX_SHADOW;

  .layoutTitle {
    margin-bottom: 4px;
    font-family: 'Gowun Batang', serif;
    font-size: sizes.$FONT_TITLE;
    color: var(--color-text1);
    text-align: center;
    user-select: none;
  }

  .layoutSubTitle {
    font-family: Raleway, sans-serif;
    color: var(--color-text2);
    text-align: center;
    letter-spacing: 0.5px;
    user-select: none;
  }

  header {
    margin-bottom: 20px;
  }
}
