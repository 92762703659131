@use 'styles/constants/sizes';
@use 'styles/mixins/flexbox';

.filteredSongListWrapper {
  @include flexbox.flexbox(start, start);
  flex-direction: column;
  width: 100%;
  height: calc(100% - 20vh - 32px - 48px);
  overflow: auto;
  border: 1px solid var(--color-border);
  border-radius: 4px;
}

.filteredSongElement {
  width: 100%;
  padding: 12px 16px;
  border-bottom: 1px solid var(--color-border);

  &:last-child {
    border-bottom: none;
  }

  .filteredSongInfo {
    dt {
      font-size: initial;
    }
  }
}
