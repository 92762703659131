$FLEX_MAP: (
  'around': space-around,
  'between': space-between,
  'center': center,
  'end': flex-end,
  'start': flex-start,
  'stretch': stretch,
);

@function _getFlexValue($key) {
  @return map-get($FLEX_MAP, $key);
}

@mixin flexbox($jc: center, $ai: center) {
  display: flex;
  align-items: _getFlexValue($ai);
  justify-content: _getFlexValue($jc);
}

@mixin inlineFlexbox($jc: center, $ai: center) {
  display: inline-flex;
  align-items: _getFlexValue($ai);
  justify-content: _getFlexValue($jc);
}
