.selectMonthWrapper {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(4, 1fr);
  height: 100%;
}

.selectMonthButton {
  transition: background-color 0.2s;
  color: var(--color-text1);

  &:hover {
    background-color: var(--color-secondary2);
  }

  &.isSelectedMonth {
    border: 4px solid var(--color-border);
  }

  &.isUnavailable {
    cursor: default;
    opacity: 0.3;

    &:hover {
      background: none;
    }
  }
}
