@use 'styles/mixins/flexbox';
@use 'styles/constants/sizes';

.calendarHeaderWrapper {
  @include flexbox.flexbox(between, center);
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;

  button {
    font-size: sizes.$FONT_SUBTITLE;
    color: var(--color-text1);
  }

  .calendarHeaderButton {
    width: 30px;
  }

  .calendarHeaderTitleButton {
    width: 150px;
    min-width: 120px;
  }

  .calendarHeaderButtonUnavailable {
    opacity: 0.5;
    &:hover {
      cursor: initial;
    }
  }
}
