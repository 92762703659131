.loadingWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Nanum Myeongjo', serif;
  color: var(--color-text1);
}
