@use 'styles/constants/sizes';
@use 'styles/mixins/flexbox';

.tagSelectorWrapper {
  width: 100%;

  .tagSelectorLabel {
    margin-bottom: 8px;
    font-family: 'Nanum Myeongjo', serif;
    font-size: sizes.$FONT_NORMAL;
    color: var(--color-text2);
  }

  .tagList {
    @include flexbox.flexbox(start, start);
    flex-flow: wrap;
    width: 100%;
    height: 12vh;
    padding: 12px 16px;
    margin-bottom: 16px;
    overflow: auto;
    font-family: 'Nanum Myeongjo', serif;
    border: 1px solid var(--color-border);
    border-radius: 4px;

    li {
      margin: 4px;
    }

    &:last-child {
      height: 8vh;
    }
  }
}
