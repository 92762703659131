@use 'styles/mixins/flexbox';

.tagBoxWrapper {
  @include flexbox.flexbox(start, center);
  flex-flow: wrap;
  width: 100%;
  overflow: auto;

  li {
    margin: 4px;
  }
}
