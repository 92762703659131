@use 'styles/constants/colors';
@use 'styles/constants/levels';
@use 'styles/mixins/flexbox';

.buttonWrapper {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: levels.$HOVER_BUTTON;
}

.hoverButton {
  width: 70px;
  height: 70px;
  overflow: hidden;
  border: 5px solid colors.$WHITE;
  border-radius: 35px;
  box-shadow: colors.$BOX_SHADOW;

  svg {
    z-index: levels.$HOVER_BUTTON_INNER;
    width: 30px;

    :root[color-primary='theme-sunrise'] &,
    :root[color-primary='theme-daylight'] & {
      fill: var(--color-text2);
    }

    :root[color-primary='theme-sunset'] &,
    :root[color-primary='theme-night'] & {
      fill: colors.$WHITE;
    }
  }
}

.hoverButtonInner {
  @include flexbox.flexbox(center, center);
  position: relative;
  width: 100%;
  height: 100%;

  :root[color-primary='theme-sunrise'] & {
    background-color: colors.$THEME_SUNRISE;
  }

  :root[color-primary='theme-daylight'] & {
    background-color: colors.$THEME_DAYLIGHT;
  }

  :root[color-primary='theme-sunset'] & {
    background-color: colors.$THEME_SUNSET;
  }

  :root[color-primary='theme-night'] & {
    background-color: colors.$THEME_NIGHT;
  }
}

.hoverButtonInner::after {
  position: absolute;
  right: -10px;
  bottom: -10px;
  width: 0;
  height: 0;
  content: '';
  transition: 0.2s linear;

  :root[color-primary='theme-sunrise'] & {
    background-color: colors.$THEME_DAYLIGHT;
  }

  :root[color-primary='theme-daylight'] & {
    background-color: colors.$THEME_SUNSET;
  }

  :root[color-primary='theme-sunset'] & {
    background-color: colors.$THEME_NIGHT;
  }

  :root[color-primary='theme-night'] & {
    background-color: colors.$THEME_SUNRISE;
  }
}

.hoverButtonInner:hover::after {
  width: 50px;
  height: 50px;
  border-radius: 35px;
}
