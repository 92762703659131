@use 'styles/constants/sizes';
@use 'styles/mixins/flexbox';

.calendarBodyWrapper {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  height: 100%;
}

.calendarButtonWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.dateWrapper {
  @include flexbox.flexbox(between, start);
  display: block;
  width: 100%;
  height: 100%;
  padding: 5px;
  font-size: sizes.$FONT_NORMAL;
  line-height: sizes.$FONT_NORMAL;
  color: var(--color-text1);
  user-select: none;
  transition: background-color 0.2s;

  &.otherMonthDate {
    opacity: 0.2;
  }

  &.songAvailable {
    background-color: var(--color-secondary2);

    &:hover {
      background-color: var(--color-secondary1);
    }
  }

  .today {
    font-weight: 700;
  }
}
