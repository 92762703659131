/* stylelint-disable value-no-vendor-prefix */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  letter-spacing: -0.05em;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::before,
  &::after {
    box-sizing: border-box;
  }

  &::-webkit-scrollbar {
    display: none; /* Chrome */
  }
}

img {
  border: 0;
}

a {
  text-decoration: none;
}

select,
input,
textarea {
  /* @miriya (2022-03-03): 사파리에서 줌 시작되지 않는 최소 사이즈
	 https://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone */
  font-size: 16px;
}

input,
button {
  -webkit-appearance: none;
}

input::-webkit-contacts-auto-fill-button {
  /* @miriya (2019-06-17): 사파리에서 자동완성 사람 아이콘 없앰 */
  position: absolute;
  right: 0;
  display: none !important;
  pointer-events: none;
  visibility: hidden;
}

input {
  background-color: transparent;
  background-image: none !important;
  border: 0;
}

button {
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 0;

  &:disabled {
    cursor: not-allowed;
  }
}

input:is([type='button'], [type='submit'], [type='reset']),
input[type='file']::file-selector-button,
button {
  color: initial;
} /* override safari default blue */
