@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
@import url('//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css');
@import url('https://fonts.googleapis.com/css2?family=Gowun+Batang&family=Nanum+Myeongjo&family=Raleway&display=swap');

body,
button,
input,
textarea {
  font-family: 'Spoqa Han Sans Neo', Inter, Arial, PingFangSC-Regular, 'Microsoft YaHei', sans-serif;
  font-feature-settings: 'tnum';
}

input[type='number'] {
  font-feature-settings: 'tnum';

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
}
